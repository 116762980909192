<template>
  <div class="container">
    <h1 style="text-align:center;">הזמנת ציוד משרדי</h1>
    <div class="warpper">
      <div class="field">
        <p>ת"ז של מבקש הבקשה</p>
        <InputText
          v-model="values.idNumber"
          type="text"
          placeholder="מס' זהות"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם פותח/ת הבקשה</p>
        <InputText
          v-model="values.ownerName"
          type="text"
          placeholder="הזן/י את שמך"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>דוא"ל לעדכון התהליך</p>
        <InputText
          v-model="values.email"
          type="text"
          placeholder="דואל"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>הערות (אופציונלי)</p>
        <Textarea v-model="values.comment" rows="5" cols="30" />
      </div>
      <div class="field">
        <p>בחירת ציוד משרדי מרשימה</p>
        <template v-for="(equipment,index) in values.selected_equipments" :key="equipment.name">
           <div class="equipment">
             <Button :disabled="index==0" @click="handle_remove_equipment(index)" icon="pi pi-minus" class="p-button-rounded p-button-danger" />
             <Dropdown style="width:50%;" v-model="equipment.name" filter optionLabel="lable" optionValue="value"  :options="equipments_options" placeholder="בחירת ציוד מתאים" />
             <el-input style="width:20%;" type="number" v-model="equipment.qnt" :step="1" :min="1"  placeholder="כמות" />
             <Button @click="handle_add_equipment" :disabled="index!=values.selected_equipments.length-1" icon="pi pi-plus" class="p-button-rounded p-button-success" />
            </div> 
        </template>
      </div>
      
      <div class="field">
        <p>
          הוספת תמונה להמחשה (רשות) - הגבלה עד 3 תמונות
        </p>
        <Button
          :disabled="values.uploadedDocuments && values.uploadedDocuments.length==3"
          v-if="!isPending"
          label="צירוף תמונה/מסמך"
          class="p-button-secondary upload-btn"
          @click="handleUpload"
        />
        <Button
          v-if="isPending"
          label="ממתין להעלאה..."
          class="p-button-secondary upload-btn"
          disabled
        />
        <input
          v-show="false"
          id="upload-image"
          type="file"
          @change="handleChange"
        />
        <div v-if="fileError" style="color:red">{{ fileError }}</div>
      </div>
      <div class="field">
        <p style="color:red;">מטופל ע"י</p>
        <Dropdown
          :options="chargeOptions"
          v-model="values.charge"
          placeholder="מטופל על ידי"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p style="color:red;">סטטוס</p>
        <Dropdown
          :options="statusOptions"
          v-model="values.status"
          placeholder="סטטוס טיפול"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p style="color:red;">הודעה לסניף</p>
        <Textarea
          v-model="values.msg"
          rows="5"
          cols="30"
          placeholder="הודעה זו תשלח בגוף המייל לפותח הבקשה"
        />
      </div>
      <div class="images">
        <template
          v-for="document in values.uploadedDocuments"
          :key="document.filePath"
        >
          <div class="image">
            <img
              v-if="document.type != 'application/pdf'"
              :src="document.url"
              @click="handleOpenPdf(document)"
            />
            <img
              v-if="document.type == 'application/pdf'"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
              @click="handleOpenPdf(document)"
            />
            <div class="delete-bar" @click="delete_file(document)">
              <p>מחק</p>
            </div>
          </div>
        </template>
      </div>
    </div>

    <Button
      label="עדכן"
      class="p-button-success send-btn"
      @click="handleSubmit"
      :disabled="isPending"
    />
    <Loader v-if="isPending" />
  </div>

  
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import swal from "sweetalert";
import {projectFirestore,projectFunctions} from '@/firebase/config'
import use_office_requests_storage from '@/Methods/use_office_requests_storage'
import { onMounted, ref } from "vue";
import {update_status_counter,convert_type_he_to_en} from '@/Methods/office_request_funcs'
import Loader from '../loader/Loader.vue'
import router from '../../../../../../router';
import { get_equipments_from_db } from '../../../../../../Methods/rosman_shivuk_funcs';

export default {
  components: { InputText, InputNumber,Dropdown, Textarea,Loader },
  props:['docId','data'],
  setup(props) {
    const { url, filePath, uploadImage, deleteImage } = use_office_requests_storage();
    const chargeOptions=ref([
      'שירז',
      'אירה',
      'עדי'
    ])
    const statusOptions=ref([
      'חדש',
      'ממתין',
      'טופל'
    ])
    const isPending = ref(false);
    const error = ref("");
    const file = ref(null);
    const fileError = ref(null);
    const old_status=ref()
    const equipments_options = ref([])

    const handleUpload = () => {
      document.getElementById("upload-image").click();
    };

    const handleOpenPdf = document => {
      window.open(document.url, "_blank");
    };

    const delete_file = async document => {
      const index = values.value.uploadedDocuments.findIndex(
        doc => (doc.filePath = document.filePath)
      );
      values.value.uploadedDocuments.splice(index, 1);
      await deleteImage(document.filePath);
    };

    const values = ref({});

    const handleChange = async e => {
      isPending.value = true;
      const types = ["image/png", "image/jpeg", "image/jpg"];
      const selected = e.target.files[0];
      console.log(selected);
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        fileError.value = null;
        if (values.value.uploadedDocuments.length < 3) {
          await uploadImage(file.value, "ishur_machala");
          values.value.uploadedDocuments.push({
            url: url.value,
            filePath: filePath.value,
            type: selected.type
          });
        }
      } else {
        console.log("error");
        file.value = null;
        fileError.value =
          "סוג קובץ זה אינו נתמך, נא להעלות קובץ תמונה או PDF בלבד!";
      }
      isPending.value = false;
    };

    const validation = () => {
      for (let key in values.value) {
        if (key == "idNumber") {
        if (values.value[key].length <7) {
            error.value = "נא למלא מספר זהות או דרכון תקין";
            return false;
        }
        }
        if (key == "ownerName") {
          if (values.value[key].length < 1) {
            error.value = "נא למלא את שם פותח הבקשה";
            return false;
          }
        }
        if (key == "email") {
          if (
            values.value[key].length == 0 ||
            !values.value[key].includes("@")
          ) {
            error.value = "נא למלא כתובת מייל תקינה";
            return false;
          }
        }
        if (key == "selected_equipments") {
          if (values.value[key].some((eq)=>!eq.name)) {
            error.value = "חסר שם מוצר באחד או יותר מהרשימה!";
            return false;
          }
        }
       
      }
      return true;
    };

    const handleSubmit = async () => {
      if (!validation()) {
        swal("בעיה", `${error.value}`, "warning");
      } else {
        isPending.value = true
        delete values.value.createdAt
        const request_collection = convert_type_he_to_en(values.value.type)
        await projectFirestore.collection('Applications').doc(props.docId).
        collection(request_collection).doc(values.value.id).set(values.value,{merge:true})
        if(old_status.value!=values.value.status){
          await update_status_counter(old_status.value,values.value.status,props.docId)
        }
        isPending.value = false
        sendEmailToUser();
        swal("הצלחה", "הטופס עודכן בהצלחה", "success");
        router.go(-1)
      }
    };

    function sendEmailToUser() {
      projectFunctions.httpsCallable('send_email')({
        html: `<div>
                <h3>
                    שלום ${values.value.ownerName},
                </h3>
                <h3>
                    בקשתך המשרדית עבור: ${values.value.type}
                </h3>
                <h3>
                    לסניף/אתר ${values.value.branche}
                </h3>
                <h3>
                    בסטטוס ${values.value.status}
                </h3>
                <h3>
                    הערות: ${values.value.msg && values.value.msg || 'אין הערות'}
                </h3>
                <h3 style="color:red;">
                    נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
                </h3>
            </div>`,
        destinations:[`${values.value.email}`],
        from:"מערכת לבקשות משרדיות",
        title:"עדכון סטטוס בקשה משרדית"
      }) 
    }


    const handle_add_equipment = ()=>{
        values.value.selected_equipments.push({
            name:'',
            qnt:'1'
        })
    }

    const init = async() => {
        const equipments = await get_equipments_from_db()
        equipments_options.value = equipments.map(eq => ({lable:eq.name,value:eq.name}))
    }

    init()

    onMounted(() => {
       isPending.value = true
      setTimeout(() => {
        if(props.data){
          values.value=props.data
          old_status.value = values.value.status
          isPending.value = false
        }
      }, 1000);
    });

    return {
      values,
      error,
      handleSubmit,
      handleChange,
      file,
      fileError,
      handleUpload,
      isPending,
      handleOpenPdf,
      delete_file,
      chargeOptions,
      statusOptions,
      equipments_options,
      handle_add_equipment
    };
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warpper {
  width: 50%;
  height: calc(100% - 100px);
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.field {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  margin-top: 15px;
}
.images {
  width: 100%;
  height: max-content;
  max-height: max-content;
  min-height: 100px;
  padding: 3px;
  display: flex;
  margin-top: 5px;
  overflow: hidden;
}
.images .image {
  position: relative;
  width: calc(100% / 3);
  height: 100%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
}
.images .image .delete-bar {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  background-color: rgb(145, 3, 3);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 5px;
}
.images .image img {
  max-width: 100%;
  max-height: calc(100% - 40px);
  border-radius: 5px;
}
.field p {
  font-size: 1.1rem;
}
.send-btn {
  margin-top: 5px;
  width: 50%;
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
input[type="time"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="time"]:hover {
  border: 1.1px solid lightblue;
}
input[type="time"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
.upload-btn {
  width: 30%;
}

@media screen and (max-width: 600px) {
  .container {
    justify-content: unset;
    overflow: hidden;
    background: var(--secondary);
    color: #fff;
  }
  .warpper {
    width: 100%;
    border: none;
    overflow-y: auto;
    flex-shrink: 0;
  }
  .send-btn {
    width: 100%;
  }
  .upload-btn {
    width: 100%;
  }
}

.equipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
</style>

